/* eslint-disable */

export default ({
  app
}) => {
  /*
   ** Seulement exécuté côté client et en mode production
   */
  if (process.env.NODE_ENV !== 'production')
    return
  /*
   ** Inclusion du script Google Analytics
   */
  // ;(function (i, s, o, g, r, a, m) {
  //   i['GoogleAnalyticsObject'] = r
  //   ;(i[r] =
  //     i[r] ||
  //     function () {
  //       ;(i[r].q = i[r].q || []).push(arguments)
  //     }),
  //     (i[r].l = 1 * new Date())
  //   ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
  //   a.async = 1
  //   a.src = g
  //   m.parentNode.insertBefore(a, m)
  // })(
  //   window,
  //   document,
  //   'script',
  //   'https://www.google-analytics.com/analytics.js',
  //   'ga'
  // )


  let gaAvailable = function () {
    return new Promise(resolve => {
      function waitForGA() {
        if (typeof ga !== "undefined") {
          //variable exists, do what you want
          resolve();
        } else {
          console.log('NO GA');
          setTimeout(waitForGA, 250);
        }
      }

      waitForGA();
    });
  }

  async () => {
    await gaAvailable();
    /*
     ** Affecter la page courante
     */
    ga('create', 'G-Q178PRMFWM', 'auto')
    /*
     ** Chaque fois que la route change
     */
    app.router.afterEach((to, from) => {
      /*
       ** Nous expliquons à Google Analytics d'ajouter une `pageview`
       */
      ga('set', 'page', to.fullPath)
      ga('send', 'pageview')
    })
  }

}