<template>
    <div class="container error-page">
        <div class="error error-div mx-auto">
            <svg class="mx-auto" xmlns="http://www.w3.org/2000/svg" width="90" height="90" fill="#DBE1EC" viewBox="0 0 48 48">
                <path d="M22 30h4v4h-4zm0-16h4v12h-4zm1.99-10C12.94 4 4 12.95 4 24s8.94 20 19.99 20S44 35.05 44 24 35.04 4 23.99 4zM24 40c-8.84 0-16-7.16-16-16S15.16 8 24 8s16 7.16 16 16-7.16 16-16 16z"></path>
            </svg>
            <h1 v-if="error && error.statusCode === 404">Page non trouvée</h1>
            <h1 v-else-if="error && error.statusCode === 403">Accès refusé</h1>
            <h1 v-else>Une erreur s'est produite</h1>
            <nuxt-link class="mx-auto" to="/">Retour à STL Viewer</nuxt-link>
        </div>
    </div>
</template>

<style>
.error-div {
    padding-top: 180px;
    width: 780px;
    text-align: center;
}

.error-page h1 {
    text-align: center;
    padding-top: 20px;
    margin-bottom: 40px;
}

.error-page a {
    text-align: center;
}
</style>

<script>
export default {
    props: ["error"],
    layout: "default",
    asyncData({ res }) {
        res.statusCode = 500;
    }
};
</script>